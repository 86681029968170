<template>
  <div class="question-page">
    <div class="page-main bg-white">
      <a-breadcrumb class="page-breadcrumb">
        <a-breadcrumb-item>首页</a-breadcrumb-item>
        <a-breadcrumb-item>题库广场</a-breadcrumb-item>
        <a-breadcrumb-item>
          {{ practice_name }}
        </a-breadcrumb-item>
      </a-breadcrumb>
      <a-spin :spinning="loading">
        <div class="container" v-if="question && question[index]">
          <div class="top_bar flex-between">
            <div class="flex">
              <div class="type">
                {{
                  question[index].question_type_id == 1
                    ? "单选题"
                    : question[index].question_type_id == 2
                    ? "多选题"
                    : "判断题"
                }}
              </div>
              <div class="number">
                <span>{{ index + 1 }}</span> / {{ question.length }}
              </div>
            </div>
            <a-button type="primary" @click="exit">退出</a-button>
          </div>
          <a-divider></a-divider>
          <div class="box">
            <!-- 1、单选2、多选3、判断 -->
            <div class="flex question_title">
              <div>{{ index + 1 }}、</div>
              <div v-html="question[index].question_title"></div>
            </div>
            <div class="option_wrap">
              <!-- 单选、判断 -->
              <template
                v-if="
                  question[index].question_type_id == 1 ||
                  question[index].question_type_id == 3
                "
              >
                <template v-for="item in [0, 1, 2, 3, 4, 5]" :key="item">
                  <div
                    v-if="question[index][`question_option_${item + 1}`]"
                    :class="
                      user_answer[index]
                        ? user_answer[index] == option[item]
                          ? user_answer[index] ==
                            question[index].question_option_right
                            ? 'option active'
                            : 'option wrong'
                          : option[item] ==
                            question[index].question_option_right
                          ? 'option active'
                          : 'option'
                        : 'option'
                    "
                    @click="chooseOption(item)"
                  >
                    {{ question[index][`question_option_${item + 1}`] }}
                  </div>
                </template>
              </template>
              <!-- 多选 -->
              <template v-if="question[index].question_type_id == 2">
                <template v-for="item in [0, 1, 2, 3, 4, 5]" :key="item">
                  <div
                    v-if="question[index][`question_option_${item + 1}`]"
                    :class="{
                      option: true,
                      wait: user_answer[index]
                        ? false
                        : temp && temp.indexOf(option[item]) > -1,
                      active: user_answer[index]
                        ? question[index].question_option_right.indexOf(
                            option[item]
                          ) > -1
                        : false,
                      wrong: user_answer[index]
                        ? question[index].question_option_right.indexOf(
                            option[item]
                          ) == -1 &&
                          user_answer[index].indexOf(option[item]) > -1
                        : false,
                    }"
                    @click="chooseOption2(item)"
                  >
                    <!-- user_answer[index]
                        ? isExsit(option[item], getIntersection(toArr(), temp))
                          ? 'active option'
                          : isExsit(option[item], getDiff(temp, toArr()))
                          ? 'option wrong'
                          : isExsit(option[item], toArr())
                          ? 'option active'
                          : 'option'
                        : isExsit(option[item], temp)
                        ? 'wait option'
                        : 'option' -->
                    {{ question[index][`question_option_${item + 1}`] }}
                  </div>
                </template>
              </template>
              <!-- <div
                v-if="question[index].question_type_id == 2"
                class="choose"
                @click="chooseMutiply"
              >
                确认答案
              </div> -->
            </div>
          </div>
          <div v-if="!user_answer[index]" class="fix_bar flex-between mb-60">
            <a-button
              type="primary"
              class="btn"
              v-if="question[index].question_type_id == 2"
              @click="chooseMutiply"
            >
              确认答案
            </a-button>
          </div>
          <div v-if="user_answer[index]" class="answer">
            <a-divider></a-divider>
            <div class="right">
              正确答案：{{ question[index].question_option_right }}
            </div>
            <div class="user_answer">
              您的答案：<span
                :class="
                  question[index].question_option_right == user_answer[index]
                    ? 'blue'
                    : 'red'
                "
              >
                {{ user_answer[index] }}
              </span>
            </div>
          </div>
          <div v-if="user_answer[index]" class="analysis">
            <a-divider></a-divider>
            <div class="title">答案解析</div>
            <div
              v-html="question[index].question_analysis"
              class="analysis_content"
            ></div>
          </div>
          <div v-if="index < question.length - 1" class="fix_bar flex-between">
            <a-button type="defaut" class="btn" @click="goLast"
              >上一题</a-button
            >
            <a-button type="primary" class="btn" @click="goNext"
              >下一题</a-button
            >
          </div>
          <div v-else class="fix_bar flex-between">
            <a-button type="defaut" class="btn" @click="exit"
              >返回题库</a-button
            >
            <a-button type="primary" class="btn" @click="resetPractice"
              >重新练习</a-button
            >
          </div>
        </div>
        <div v-if="question.length == 0 && !loading" class="text-center">
          <a-empty description="暂无练习" class="mb-30"></a-empty>
          <a-button type="primary" @click="goToIndex">返回首页</a-button>
        </div>
      </a-spin>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, watch } from "@vue/runtime-core";
import { message } from "ant-design-vue";
import url from "../../utils/url";
import { getPracticeQuestionList } from "@/api/main.js";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "QuestionQuestion",
  setup() {
    const route = useRoute();
    const pageData = reactive({
      question: [],
      practice_id: null,
      index: 0,
      current_question: null,
      user_answer: [],
      option: ["A", "B", "C", "D", "E", "F"],
      temp: [],
      practice_name: null,
      loading: true,
    });
    const chooseOption = (item) => {
      let option = ["A", "B", "C", "D", "E", "F"];
      if (!pageData.user_answer[pageData.index]) {
        pageData.user_answer.push(option[item]);
      }
    };
    const isExsit = (option, temp) => {
      if (temp.indexOf(option) != -1) {
        return true;
      } else {
        return false;
      }
    };
    const chooseMutiply = () => {
      if (pageData.temp.length == 0) {
        message.error("请选择选项");
        return;
      }
      let tempStr = pageData.temp.sort().join(",");
      pageData.user_answer.push(tempStr);
    };
    const chooseOption2 = (item) => {
      let option = ["A", "B", "C", "D", "E", "F"];
      if (pageData.temp.indexOf(option[item]) != -1) {
        let index = pageData.temp.indexOf(option[item]);
        pageData.temp.splice(index, 1);
      } else {
        pageData.temp.push(option[item]);
      }
    };
    //取交集
    const getIntersection = (arr1, arr2) => {
      let intersection = arr1.filter(function (val) {
        return arr2.indexOf(val) > -1;
      });
      return intersection;
    };
    //取差集
    const getDiff = (arr1, arr2) => {
      let diff = arr1.filter(function (val) {
        return arr2.indexOf(val) === -1;
      });
      return diff;
    };
    const toArr = () => {
      return pageData.question[pageData.index].question_option_right.split(",");
    };
    const goLast = () => {
      pageData.temp = [];
      if (pageData.index == 0) {
        message.error("已经是第一题！");
      } else {
        pageData.index--;
      }
    };
    const goNext = () => {
      if (pageData.index == pageData.question.length - 1) {
        message.error("已经是最后一题！");
      } else {
        if (!pageData.user_answer[pageData.index]) {
          message.error("请确认本题答案！");
          return;
        } else {
          pageData.index++;
        }
        // pageData.user_answer = '';
      }
      pageData.temp = [];
    };
    const exit = () => {
      url.replaceTo("/question/index");
    };
    const resetPractice = () => {
      pageData.user_answer = [];
      pageData.temp = [];
      pageData.index = 0;
      //   getPracticeQuestionList();
    };
    const getPracticeQuestionListFun = () => {
      getPracticeQuestionList({ practice_id: pageData.practice_id })
        .then((res) => {
          if (res.code == 200) {
            pageData.question = res.data.question;
          } else {
            message.error(res.msg);
          }
        })
        .catch((res) => {
          console.log(res);
        })
        .finally(() => {
          pageData.loading = false;
        });
    };
    // if (route?.query?.practiceId) {
    //   pageData.practice_id = route?.query?.practiceId;
    //   pageData.practice_name = route?.query?.practiceName;
    //   getPracticeQuestionListFun();
    // } else {
    //   message.error("暂无练习");
    // }

    watch(
      () => route.query,
      (val) => {
        if (route.path == "/question/question") {
          if (val?.practiceId) {
            pageData.loading = true;
            pageData.practice_id = route?.query?.practiceId;
            pageData.practice_name = route?.query?.practiceName;
            getPracticeQuestionListFun();
          } else {
            pageData.question = [];
            pageData.practice_id = "";
            pageData.practice_name = "";
            pageData.loading = false;
            message.error("暂无练习");
          }
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    const goToIndex = () => {
      url.replaceTo("/index");
    };

    return {
      ...toRefs(pageData),
      chooseOption,
      resetPractice,
      exit,
      goNext,
      getDiff,
      goLast,
      getIntersection,
      toArr,
      chooseOption2,
      chooseMutiply,
      isExsit,
      goToIndex,
    };
  },
});
</script>

<style lang="less" scoped>
.question-page {
  height: 100%;
  .container {
    width: 600px;
    margin: 0 auto;
    padding: 30px 0 60px;
  }
}

.plr-20 {
  padding: 0 20px;
}

.top_bar {
  //   height: 110px;
  padding: 0 20px;
  font-size: 14px;
  align-items: center;

  .type {
    color: #3c9af4;
    margin-right: 20px;
    position: relative;

    &::after {
      content: "";
      width: 44px;
      height: 7px;
      background-color: #2a68e6;
      border-radius: 3px;
      position: absolute;
      bottom: -12px;
      left: 0;
    }
  }

  .number {
    color: #969696;
    flex: 0 0 auto;
    text {
      color: #333333;
    }
  }

  .exit {
    width: 120px;
    height: 50px;
    background-color: #eef7ff;
    border-radius: 10px;
    color: #1e83e5;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.box {
  padding: 8px 20px;

  .question_title {
    color: #333333;
    font-size: 16px;
    margin-bottom: 60px;
    align-items: unset;
  }
}

.answer {
  padding: 0 20px;
  font-size: 16px;

  .right {
    color: #3c9af4;
    margin-bottom: 12px;
  }

  .user_answer {
    color: #333333;

    .blue {
      color: #3c9af4;
    }

    .red {
      color: #bb2f2f;
    }
  }
}

.analysis {
  padding: 0 20px 20px;

  .title {
    font-size: 18px;
    color: #333333;
    padding-left: 24px;
    position: relative;
    margin-bottom: 12px;

    &:before {
      content: "";
      width: 4px;
      height: 100%;
      background: #1890ff;
      background-blend-mode: normal, normal;
      border-radius: 1px;
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  .analysis_content {
    color: #616161;
    font-size: 14px;
  }
}

.option_wrap {
  padding-bottom: 40px;
  display: flex;
  flex-wrap: wrap;
  .option {
    width: 48%;
    // height: 100px;
    // line-height: 100px;
    background-color: #eeeeee;
    border-radius: 50px;
    margin: 0 auto 20px auto;
    text-align: center;
    color: #333333;
    font-size: 16px;
    padding: 12px 20px;
    box-sizing: border-box;
    cursor: pointer;
  }

  .active,
  .wait {
    background-color: #1e83e5;
    color: #ffffff;
  }

  .wrong {
    background-color: #bb2f2f;
    color: #ffffff;
  }

  // .wait {
  //   background-color: #1e83e5;
  //   color: #ffffff;
  // }
}

.fix_bar {
  //   position: fixed;
  //   padding: 10px 2;
  width: 100%;
  //   left: 0;
  //   bottom: 0;
  box-sizing: border-box;
  background-color: #ffffff;
  justify-content: center;
  .btn {
    width: 200px;
  }
  .btn:first-child {
    margin-right: 30px;
  }
  .q_btn {
    width: 311px;
    height: 80px;
    line-height: 80px;
    text-align: center;
    font-size: 16px;
    border-radius: 10px;
  }

  .last {
    background-color: #eeeeee;
    color: #969696;
  }

  .next {
    background-color: #1e83e5;
    color: #ffffff;
  }
}

.choose {
  width: 600px;
  height: 100px;
  line-height: 100px;
  background-color: #1e83e5;
  border-radius: 50px;
  color: #ffffff;
  text-align: center;
  margin: 0 auto;
}
</style>
